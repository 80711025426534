<style lang="less" scoped>
  @import "../../assets/css/variables";

  .customer-detail {
    // width: 40%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }

  .dialog-form {
    width: 80%;
    margin: 0 auto;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>报警配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getCustomerByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 200px"
              v-model="filters.key"
              placeholder="请输入配置名称/编码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getCustomerByName">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="alertConfigList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" align="center" width="80">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="alertCode"
          :show-overflow-tooltip="true"
          label="编码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alertName"
          :show-overflow-tooltip="true"
          label="名称"
          align="center"
        ></el-table-column>
        <!-- <el-table-column :show-overflow-tooltip="true" min-width="100" label="创建时间">
          <template slot-scope="scope">
            {{scope.row.createTime | formatTime}}
          </template>
        </el-table-column> -->
        <el-table-column
          v-if="user.roleCode === 'admin'"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
              class="m-l-xs"
            >
              修改
            </el-button>
            <el-button
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          v-if="user.roleCode === 'admin'"
          type="primary"
          @click="dialogShow = true"
        >
          新 增
        </el-button>
        <el-button
          v-if="user.roleCode === 'admin'"
          type="danger"
          @click="batchRemove"
          :disabled="!this.sels.length > 0"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :title="isEdit ? '修改配置信息' : '新增'"
      size="small"
      :visible.sync="dialogShow"
    >
      <el-form :model="configInfo" label-width="80px" label-position="right">
        <el-form-item prop="alertName" label="名称：">
          <el-input v-model="configInfo.alertName"></el-input>
        </el-form-item>

        <el-form-item prop="alertCode" label="编码：">
          <el-input v-model="configInfo.alertCode"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="margin-top: -20px">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDvc">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getAlertConfigByKey,
    removeAlertConfig,
    updateAlertConfig,
    createAlertConfig,
    updateAlarmReaded,
  } from "../../services/alarm";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {
        isEdit: false,
        loaded: false,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        alertConfigList: [],
        filters: {
          key: null,
        },
        configInfo: {
          alertName: "",
          alertCode: "",
        },
        listLoading: false,
        dialogShow: false,
        sels: [], //列表选中列
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    filters: {
      formatTime(row) {
        return row ? moment(row).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    methods: {
      /**
       * 判断编辑权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        console.log(this.user);
        this.editPermission = permissions.some((item) => {
          return item === "Customer:update";
        });
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeAlertConfig(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryConfigList();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.dialogShow = true;
        // row;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedConfig = Object.assign({}, row);
        this.configInfo = {
          id: choosedConfig.id,
          alertCode: choosedConfig.alertCode,
          alertName: choosedConfig.alertName,
        };
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeAlertConfig(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryConfigList();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据客户名筛选
       */
      getCustomerByName() {
        this.queryConfigList();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "config", query: { page: page } });
          this.queryConfigList();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryConfigList();
      },

      /**
       * 获取所有的报警
       */
      async queryConfigList() {
        this.listLoading = true;
        if (this.filters.key === "") {
          this.filters.key = null;
        }
        let searchParam = Object.assign({}, this.filters);
        const responseData = await getAlertConfigByKey(searchParam, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.alertConfigList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },
      async updateAlertConfit() {
        let res = null;
        const info = Object.assign({}, this.configInfo);
        if (this.isEdit) {
          res = await updateAlertConfig(info);
        } else {
          res = await createAlertConfig(info);
        }
        if (res && res.errorCode === 0) {
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
        } else {
          Notification.warning({
            title: "失败",
            message: this.isEdit ? "修改失败" : "新增失败",
          });
        }
        this.queryConfigList();
      },
      /**
       * 提交表单
       */
      submitDvc() {
        this.dialogShow = false;
        this.updateAlertConfit();
      },
    },
    async created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryConfigList();
      // this.checkEditPermission();
    },
    watch: {
      dialogShow(newVal, oldVal) {
        if (newVal === false) {
          this.isEdit = false;
          this.configInfo = {
            alertName: "",
            alertCode: "",
          };
        }
      },
    },
  };
</script>
